import React from "react";
import { FaEdit } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { FaRegMap } from "react-icons/fa";
import { IoMdGlobe } from "react-icons/io";
import { HiMiniLanguage } from "react-icons/hi2";
import { LuLink } from "react-icons/lu";
import { Link } from "react-router-dom";

const settingsTwo = [
  {
    name: "Terms & Conditions",
    canEdit: false,
    href: "/policy/:name",
    icon: LuLink,
  },
  {
    name: "Refund Policy",
    canEdit: false,
    icon: LuLink,
    href: "/policy/:name",
  },
  {
    name: "Privacy Policy",
    canEdit: false,
    icon: LuLink,
    href: "/policy/:name",
  },
  {
    name: "Cancellation Policy",
    canEdit: false,
    icon: LuLink,
    href: "/policy/:name",
  },
];

const getPolicyUrl = (name) => {
  switch (name) {
    case "Terms & Conditions":
      return "/policy/terms_and_conditions";
    case "Refund Policy":
      return "/policy/refund_policy";
    case "Privacy Policy":
      return "/policy/privacy_policy";
    case "Cancellation Policy":
      return "/policy/cancellation_policy";
    default:
      return "/policy/:name";
  }
};

const SettingsListTwo = () => {
  return (
    <ul
      role="list"
      className="divide-y divide-gray-100 overflow-hidden bg-white ring-gray-900/5 sm:rounded-xl"
    >
      {settingsTwo.map((item) => (
        <li
          key={item.name}
          className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
        >
          <Link to={getPolicyUrl(item.name)}>
            <div className="flex gap-x-4">
              <item.icon className="text-2xl" />
              <div className="min-w-0 flex-auto">
                <p className="text-md leading-6 text-gray-700">
                  <span className="absolute inset-x-0 -top-px bottom-0 text-gray-600" />
                  {item.name}
                </p>
                <p className="text-sm text-orange-500">Read</p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500"></p>
              </div>
            </div>
            <div className="flex items-center gap-x-4 cursor-pointer hover:scale-105">
              {item.canEdit && (
                <FaEdit
                  className="h-5 w-5 flex-none text-gray-400 cursor-pointer hover:scale-105"
                  aria-hidden="true"
                />
              )}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SettingsListTwo;
