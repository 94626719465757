import React from "react";

const Hello = () => {
  const steps = [
    "Download and Install Postman:",
    "Visit the Postman website.",
    "Choose the version compatible with your operating system (Windows, macOS, or Linux).",
    "Download and install the application.",

    "Launch Postman:",
    "Open the Postman application after installation.",

    "Import the Provided Postman API Files:",
    "Click on the 'Import' button in the top left corner of the Postman application.",
    "Choose the method you want to use to import the API files. You can either drag and drop your Postman collection files or click 'Upload Files' to browse your file system and select the files.",
    "Click 'Import' to add the API collection to Postman.",

    "Add Authorization Key:",
    "Open the imported collection or a specific request within the collection.",
    "Navigate to the 'Authorization' tab.",
    "Select the type of authorization your API requires (e.g., Bearer Token, API Key, Basic Auth).",
    "Enter the necessary credentials or token. For example, if using a Bearer Token, select 'Bearer Token' from the dropdown and enter your token in the provided field.",

    "Test the APIs:",
    "Select the desired API endpoint from the imported collection.",
    "Ensure the request method (GET, POST, PUT, DELETE, etc.) matches the API specification.",
    "Enter any required parameters, headers, or body content as specified by the API documentation.",
    "Click the 'Send' button to execute the request.",
    "Review the response returned by the API in the lower section of the Postman interface. This section will display the status code, response time, and response body.",

    "Review and Debug:",
    "Use the response details to verify if the API is working as expected.",
    "If the response is not as expected, check the request details, headers, parameters, and authorization settings.",
    "Make any necessary adjustments and retest as needed.",

    "Save and Document:",
    "Save your successful test cases for future reference.",
    "Document any observations or issues encountered during testing.",
  ];

  return (
    <div className="flex flex-col space-y-6 justify-center items-center text-center h-auto tracking-tight text-lg font-medium">
      <div className="text-9xl font-bold mb-4 h-screen flex justify-center items-center text-center">
        👋 Hello!
      </div>
      <h2 className="text-2xl font-semibold mb-4 ">
        Steps to Test APIs using Postman
      </h2>
      <ol className="list-decimal list-inside text-left space-y-2 px-40">
        {steps.map((step, index) => (
          <li key={index} className="ml-4">
            {step}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Hello;
