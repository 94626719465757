import React, { useState } from "react";
import { motion } from "framer-motion";
import { Modal } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SendingMessage = ({ key, chat }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Track selected image index for carousel

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index); // Set the selected image index for carousel
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const renderFiles = (files) => {
    if (files.length === 1) {
      return (
        <div className="w-full h-auto aspect-w-1 aspect-h-1">
          <img
            src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${files[0].stored_file_name}`}
            alt={files[0].original_file_name}
            className="object-cover w-full h-full rounded-lg"
            style={{ maxWidth: "400px", maxHeight: "500px" }}
            onClick={() => handleOpenModal(0)} // Open modal on image click
          />
        </div>
      );
    } else if (files.length > 1) {
      const gridItems = files.slice(0, 4).map((file, index) => (
        <div key={index} className="relative w-full aspect-w-1 aspect-h-1">
          <img
            src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
            alt={file.original_file_name}
            className="object-cover w-full h-full rounded-lg cursor-pointer"
            style={{ maxWidth: "300px", maxHeight: "200px" }}
            onClick={() => handleOpenModal(index)} // Open modal on image click
          />
          {index === 3 && files.length > 4 && (
            <div
              className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer rounded-lg"
              onClick={() => handleOpenModal(index)}
            >
              <span className="text-white text-2xl">+{files.length - 4}</span>
            </div>
          )}
        </div>
      ));

      return <div className="grid grid-cols-2 gap-2">{gridItems}</div>;
    }
  };

  const customArrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 20px)",
    width: 40,
    height: 40,
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    border: "none",
    outline: "none",
    color: "#fff",
    fontSize: "1.6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="w-full">
      <div className="p-3 flex justify-end" key={key}>
        <motion.div
          initial={{ y: 50, scale: 0.5, opacity: 0 }}
          animate={{ y: 0, scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="bg-[#404041] p-6 text-md text-white rounded-xl mb-4 ml-5"
          style={{
            minWidth: "50px",
            maxWidth: "600px",
            minHeight: "30px",
            wordWrap: "break-word",
            overflow: "hidden",
          }}
        >
          {chat.message}
          {chat.conversation_files && chat.conversation_files.length > 0 && (
            <div className="mt-2">{renderFiles(chat.conversation_files)}</div>
          )}
        </motion.div>
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
        bodyStyle={{ padding: 0 }}
        centered
      >
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          selectedItem={selectedImageIndex}
          onChange={(index) => setSelectedImageIndex(index)}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...customArrowStyles, left: 15 }}
              >
                &#8249;
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...customArrowStyles, right: 15 }}
              >
                &#8250;
              </button>
            )
          }
          dotStyle={{
            background: "rgba(255, 255, 255, 0.8)",
            width: 12,
            height: 12,
            margin: "0 5px",
            borderRadius: "50%",
            border: "none",
            outline: "none",
          }}
          dotActiveStyle={{
            background: "#fff",
            width: 12,
            height: 12,
            margin: "0 5px",
            borderRadius: "50%",
            border: "none",
            outline: "none",
          }}
        >
          {chat.conversation_files.map((file, index) => (
            <div
              key={index}
              className="relative w-full aspect-w-1 aspect-h-1 p-3"
            >
              <img
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
                alt={file.original_file_name}
                className="object-cover w-full h-[36rem] rounded-lg"
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </div>
  );
};

export default SendingMessage;
