import React, { useState, useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NotiLoader from "../loading/NotiLoader";
const AllNotifications = () => {
  const [showAll, setShowAll] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification?limit=100&offset=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setNotifications(response.data.content.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);
  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    if (now.isSame(createdAt, "day")) {
      const hoursDiff = now.diff(createdAt, "hours");
      const minutesDiff = now.diff(createdAt, "minutes");
      const secondsDiff = now.diff(createdAt, "seconds");

      if (hoursDiff > 0) {
        return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
      } else if (minutesDiff > 0) {
        return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
      } else {
        return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
      }
    } else {
      return createdAt.fromNow();
    }
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };
  const handleNotificationClick = (channelId) => {
    localStorage.setItem("notichannelId", channelId);
    navigate("/messages");
  };
  if (loading) {
    return (
      <div>
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
      </div>
    );
  }
  return (
    <div className="px-10">
      {" "}
      <ul role="list" className="divide-y divide-gray-100">
        {notifications.map((notification, index) => (
          <li
            key={notification.id}
            className="flex justify-between gap-x-6 py-5 hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
            onClick={() => handleNotificationClick(notification.channel_id)}
          >
            <div className="flex min-w-0 gap-x-4">
              <img
                className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500  ${
                  notification.sender_user.type === "employer"
                    ? "ring-2 ring-orange-500"
                    : "ring-2 ring-gray-700"
                }`}
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${notification.sender_user.profile_image}`}
                alt="Profile"
              />
              <div className="min-w-0 flex-auto">
                <p className="text-md font-semibold leading-6 text-gray-900">
                  {notification.sender_user.first_name}{" "}
                  {notification.sender_user.last_name}
                </p>
                <p
                  className={`text-xs flex items-center ${
                    notification.sender_user.type === "job-seeker"
                      ? "text-black"
                      : "text-orange-500"
                  }`}
                >
                  {capitalizeFirstLetter(notification.sender_user.type)}
                  <IoIosCheckmarkCircle className="ml-1" />
                </p>
                <div className="mt-4 flex  text-sm">
                  {notification.description}
                </div>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="mt-1 text-xs leading-5 text-gray-500">
                <p>{getTimeAgo(notification.created_at)}</p>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllNotifications;
