import React, { useState, useEffect } from "react";
import axios from "axios";
import NotificationCards from "./NotificationCards";
import { IoCaretUpOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import NotificationShimmer from "./NotificationShimmer";

const Notification = ({ list }) => {
  const [showAll, setShowAll] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification?limit=100&offset=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setNotifications(response.data.content.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  if (loading) {
    return <NotificationShimmer />;
  }

  return (
    <div className="p-0 px-5 flex flex-col h-screen bg-transparent overflow-hidden">
      <span className="text-lg font-bold text-black mb-1 h-10 flex justify-between items-center">
        Notifications
        {showAll && (
          <>
            <IoCaretUpOutline
              className="text-2xl cursor-pointer text-orange-600/60"
              onClick={toggleShowAll}
              data-tooltip-id="collapseTooltip"
              data-tooltip-content="Collapse"
            />
            <Tooltip id="collapseTooltip" place="top" effect="solid" />
          </>
        )}
      </span>
      <div className="overflow-y-auto flex-1 p-0 m-0 mb-44 scrollbar-hide">
        {notifications.length > 0 ? (
          <NotificationCards
            notifications={notifications}
            list={list}
            showAll={showAll}
            setShowAll={setShowAll}
            toggleShowAll={toggleShowAll}
          />
        ) : (
          <div className="flex items-center justify-center w-full">
            <div className="flex items-center justify-center h-64 w-full p-10 bg-black/10 rounded-2xl shadow-md">
              <span className="text-orange-400 text-xl tracking-tighter">
                No notifications found
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
