import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import avatar from "../../assets/lady.jpg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { CiBookmark } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import UserShimmer from "../loading/UserShimmer";
import NotiLoader from "../loading/NotiLoader";

const ChatList = ({ loading, messagedata, openChat }) => {
  const storedUserId = localStorage.getItem("userId");
  const notichannelId = localStorage.getItem("notichannelId");
  const [chatOpened, setChatOpened] = useState(false);

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    if (now.isSame(createdAt, "day")) {
      const hoursDiff = now.diff(createdAt, "hours");
      const minutesDiff = now.diff(createdAt, "minutes");
      const secondsDiff = now.diff(createdAt, "seconds");

      if (hoursDiff > 0) {
        return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
      } else if (minutesDiff > 0) {
        return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
      } else {
        return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
      }
    } else {
      return createdAt.fromNow();
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  const handleClick = (user, otherUser) => {
    const lastMessage =
      user.channel_last_conversation && user.channel_last_conversation.message
        ? user.channel_last_conversation.message
        : null;

    if (lastMessage) {
      localStorage.setItem("lastmsg", lastMessage);
    } else {
      localStorage.removeItem("lastmsg");
    }

    if (user.post && user.post.description) {
      localStorage.setItem("postDescription", user.post.description);
    } else {
      localStorage.removeItem("postDescription");
    }

    localStorage.setItem("channelId", otherUser.channel_id);

    openChat(otherUser, lastMessage);
  };

  useEffect(() => {
    if (notichannelId && !chatOpened) {
      messagedata.forEach((user) => {
        const otherUser = user.channel_users.find(
          (channelUser) => channelUser.user.id !== storedUserId
        );

        if (otherUser && otherUser.channel_id === notichannelId) {
          handleClick(user, otherUser);
          setChatOpened(true); // Ensure the chat is only opened once
        }
      });
    }
  }, [notichannelId, messagedata, storedUserId, chatOpened]);

  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
        </div>
      ) : (
        <div>
          {messagedata.map((user, index) => {
            const otherUser = user.channel_users.find(
              (channelUser) => channelUser.user.id !== storedUserId
            );

            if (!otherUser) {
              return null;
            }

            return (
              <div
                key={index}
                className="p-3 bg-white rounded-xl mb-3 w-full scrollbar-hide mt-5 px-8"
              >
                <div className="flex">
                  <img
                    className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500 ${
                      otherUser.user.type === "employer"
                        ? "ring-2 ring-orange-500"
                        : "ring-2 ring-gray-700"
                    }`}
                    src={
                      otherUser.user.profile_image !== "default.png" &&
                      otherUser.user.profile_image
                        ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${otherUser.user.profile_image}`
                        : avatar
                    }
                    alt="User Avatar"
                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                  />
                  <div
                    className="flex flex-col ml-2 cursor-pointer"
                    onClick={() => handleClick(user, otherUser)}
                  >
                    <span className="text-lg font-bold text-gray-600">
                      {otherUser.user.first_name} {otherUser.user.last_name}
                    </span>
                    <span
                      className={`text-sm flex items-center ${
                        otherUser.user.type === "job-seeker"
                          ? "text-black"
                          : "text-orange-400"
                      }`}
                    >
                      <span
                        className={`text-sm text-[#5B5B5B] ${
                          otherUser.user.username === "@premium" ||
                          otherUser.user.username === "@Test"
                            ? "text-[#5B5B5B] "
                            : ""
                        }`}
                      >
                        {otherUser.user.username}
                      </span>
                      <GoDotFill className="ml-1 text-xs mr-1" />
                      {capitalizeFirstLetter(otherUser.user.type)}
                      <IoIosCheckmarkCircle className="ml-1 text-lg" />
                    </span>
                  </div>
                  <div className="flex justify-end text-xs flex-col ml-auto relative z-50">
                    <Menu>
                      <MenuButton>
                        <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
                      </MenuButton>
                      <Transition
                        enter="transition ease-out duration-75"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <MenuItems
                          anchor="bottom end"
                          className="w-auto origin-top-right rounded-xl border border-black/30 bg-white p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md"
                        >
                          <MenuItem>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-black/30 text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                Pin to the Top
                              </button>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-black/30 text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                Block User
                              </button>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-black/30 text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                Report User
                              </button>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-black/30 text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                Delete Chat
                              </button>
                            )}
                          </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                    <p>{getTimeAgo(user.updated_at)}</p>
                  </div>
                </div>
                {user.post && (
                  <div className="flex flex-col mt-3 px-14">
                    <div className="flex items-center mt-1">
                      <span className="text-md text-gray-600">
                        {user.post.description}
                      </span>
                    </div>
                  </div>
                )}
                <div className="flex px-3 w-full mt-3">
                  <CiBookmark className="text-2xl ml-auto flex mb-3" />
                </div>
                <hr />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ChatList;
