import React, { useState, useEffect } from "react";
import SearchMessage from "./SearchMessage";
import ChatComponent from "./ChatComponent";
import ChatList from "./ChatList";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AllMessages = (props) => {
  const { messagedata } = props;
  const navigate = useNavigate();
  const [chatOpened, OpenChat] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatlistData, setChatlistData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getChatList = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/channel-list`,
        {
          params: {
            limit: "100",
            offset: 1,
            bookmark: "no",
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setChatlistData(response.data.content.data);
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatList();
  }, []);

  const openChat = (chatData) => {
    setSelectedChat([chatData]);

    OpenChat(true);
  };
  return (
    <div className="w-full">
      {!chatOpened && (
        <div>
          <hr />
          <ChatList
            messagedata={chatlistData}
            openChat={openChat}
            loading={loading}
          />
        </div>
      )}
      <div>
        {chatOpened && (
          <ChatComponent chatData={selectedChat} OpenChat={OpenChat} />
        )}
      </div>
    </div>
  );
};

export default AllMessages;
