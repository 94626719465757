import React, { useEffect, useState, useCallback, useRef } from "react";
import PublishPost from "./PublishPost";
import Posts from "./Posts";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Loading from "../loading/Loading";
import PostShimmer from "../loading/PostShimmer";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

const HomeMain = () => {
  const navigate = useNavigate();
  const [postdatas, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [totalPosts, setTotalPosts] = useState(null);
  console.log(totalPosts, "totalPosts");
  const [userExists, setUserExists] = useState(false);
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [postLimit, setPostLimit] = useState(10);
  const lastApiCallTime = useRef(0);
  const [channelLoading, setChannelLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const fetchPosts = useCallback(
    async (reset = false) => {
      const currentTime = Date.now();
      if (currentTime - lastApiCallTime.current < 3000) {
        return; // If the last API call was made less than 3 seconds ago, do nothing
      }
      lastApiCallTime.current = currentTime;

      setLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const token = localStorage.getItem("token");
        let url, params;

        const tagParams = {};
        activeTags.forEach((tag) => {
          switch (tag) {
            case "job_seeker_post":
              tagParams.job_seeker_post = 1;
              break;
            case "emp_profile":
              tagParams.emp_profile = 1;
              break;
            case "job_seeker_profile":
              tagParams.job_seeker_profile = 1;
              break;
            case "emp_post":
              tagParams.emp_post = 1;
              break;
            default:
              break;
          }
        });

        if (token) {
          url = `${baseURL}/api/v1/user/post/list`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        } else {
          url = `${baseURL}/api/v1/user/post/listoffline`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        }

        const response = await axios.get(url, {
          params,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        const postDataStructure = response.data.content.data;
        const total = response.data.content.total;

        setTotalPosts(total);
        if (reset) {
          setPosts(postDataStructure);
        } else {
          setPosts((prev) => {
            const newPosts = postDataStructure.filter(
              (post) =>
                !prev.some((existingPost) => existingPost.id === post.id)
            );
            return [...prev, ...newPosts];
          });
        }

        setLoading(false);
        setFetching(false);
      } catch (err) {
        setLoading(false);
        setFetching(false);
      }
    },
    [postLimit, searchText, activeTags, totalPosts]
  );

  const debouncedFetchPosts = useCallback(
    debounce((reset) => {
      fetchPosts(reset);
    }, 300),
    [fetchPosts]
  );

  const handleInfiniteScroll = async () => {
    if (totalPosts && postdatas.length >= totalPosts) {
      return; //! Stop fetching more posts if we have already fetched all posts
    }

    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (!fetching) {
          setFetching(true);
          setPostLimit((prev) => prev + 10);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  useEffect(() => {
    fetchPosts(true);
  }, [postLimit, fetchPosts, totalPosts]);

  useEffect(() => {
    if (searchText.length === 0) {
      setPostLimit(10);
      debouncedFetchPosts(true);
    } else if (searchText.length >= 3) {
      setPostLimit(100);
      debouncedFetchPosts(true);
    }
  }, [searchText, debouncedFetchPosts]);

  useEffect(() => {
    const refreshScrollListener = () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
      window.addEventListener("scroll", handleInfiniteScroll);
    };
    refreshScrollListener();
  }, [fetching]);

  const handleBookmark = async (userId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("User bookmarked successfully");
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast.error("Error bookmarking user. Please try again later.");
    }
  };

  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post reported successfully");
    } catch (error) {
      console.error("Error reporting post:", error);
      toast.error("Error reporting post. Please try again later.");
    }
  };
  const handleDeletePost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/delete/${postId}`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post deleted successfully");
      fetchPosts(true);
    } catch (error) {
      console.error("Error deleted post:", error);
      toast.error("Error deleted post. Please try again later.");
    }
  };
  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading(true);
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setChannelLoading(false);

      toast.success("Chat channel created successfully");
      navigate("/messages");
    } catch (error) {
      setChannelLoading(false);
      toast.error("Error creating chat channel. Please try again later.");
    }
  };
  return (
    <div className="scrollbar-hide w-auto">
      <h1 className="text-lg font-bold mb-2 px-8">Homepage</h1>
      <hr />
      <PublishPost
        fetchPosts={fetchPosts}
        setLoadingpost={setLoading}
        userExists={userExists}
      />
      <hr />
      <h2 className="text-lg font-bold mt-1 px-8 mb-2">Latest Posts</h2>
      {loading && !fetching ? (
        <div className="flex flex-col">
          <PostShimmer />
          <PostShimmer />
          <PostShimmer />
        </div>
      ) : (
        <Posts
          postdatas={postdatas}
          handleBookmark={handleBookmark}
          handleReportPost={handleReportPost}
          createChatChannel={createChatChannel}
          channelLoading={channelLoading}
          handleDeletePost={handleDeletePost}
        />
      )}
      {fetching && <Loading />}
    </div>
  );
};

export default HomeMain;
