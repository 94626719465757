import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";
import { IoShareOutline } from "react-icons/io5";
import { MdOutlineModeComment } from "react-icons/md";
import { CiBookmark } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import SearchMessage from "./SearchMessage";
import SingleChat from "./SingleChat";
import { GoDotFill } from "react-icons/go";
import avatar from "../../assets/lady.jpg";
import { IoIosArrowRoundBack } from "react-icons/io";

const ChatPerson = ({ chatData, message, OpenChat }) => {
  const [userLastmsg, setUserLastMessage] = useState("");

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    if (now.isSame(createdAt, "day")) {
      const hoursDiff = now.diff(createdAt, "hours");
      const minutesDiff = now.diff(createdAt, "minutes");
      const secondsDiff = now.diff(createdAt, "seconds");

      if (hoursDiff > 0) {
        return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
      } else if (minutesDiff > 0) {
        return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
      } else {
        return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
      }
    } else {
      return createdAt.fromNow();
    }
  };
  useEffect(() => {
    const msg = localStorage.getItem("postDescription");
    if (msg) {
      setUserLastMessage(msg);
    } else return;
  }, []);

  return (
    <div>
      <IoIosArrowRoundBack
        className=" mt-2 ml-8 text-3xl cursor-pointer hover:scale-95"
        onClick={() => OpenChat(false)}
      />{" "}
      {chatData.map((post, index) => (
        <div
          key={index}
          className="py-3 bg-white rounded-xl mb-3 w-full scrollbar-hide  px-8"
        >
          <div className="flex">
            <img
              className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500 ${
                post.user.type === "employer"
                  ? "ring-2 ring-orange-500"
                  : "ring-2 ring-gray-700"
              }`}
              src={
                post.user.profile_image !== "default.png" &&
                post.user.profile_image
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user.profile_image}`
                  : avatar
              }
              alt="User Avatar"
              style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
            />
            <div className="flex flex-col ml-2">
              <span className="text-lg font-bold text-gray-600">
                {post.user.first_name} {post.user.last_name}
              </span>
              <span
                className={`text-sm flex items-center ${
                  post.user.type === "job-seeker"
                    ? "text-black"
                    : "text-orange-400"
                }`}
              >
                <span
                  className={`text-sm text-[#5B5B5B] ${
                    post.user.username === "@premium" ||
                    post.user.username === "@Test"
                      ? "text-[#5B5B5B] "
                      : ""
                  }`}
                >
                  {post.user.username}
                </span>
                <GoDotFill className="ml-1 text-xs mr-1" />
                {capitalizeFirstLetter(post.user.type)}
                <IoIosCheckmarkCircle className="ml-1 text-lg" />
              </span>
            </div>
            <div className="flex justify-end text-xs flex-col ml-auto relative">
              <Menu>
                <MenuButton>
                  <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
                </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <MenuItems
                    anchor="bottom end"
                    className="w-auto origin-top-right rounded-xl border border-black/30 bg-white p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md"
                  >
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Pin to the Top
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Block User
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Report User
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Delete Chat
                        </button>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
              {/* {moment(post.time).format("h:mm:ss a")} */}
            </div>
          </div>

          <div className="mt-3 ml-14 mb-3">{userLastmsg}</div>

          <hr />
        </div>
      ))}
    </div>
  );
};

export default ChatPerson;
