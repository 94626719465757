import React from "react";
import NoDataJson from "../assets/nodata.json";
import Lottie from "react-lottie";
const NoDataFound = ({ text }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: NoDataJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <div className=" top-0 w-full mx-auto flex flex-col">
        <Lottie options={lottieOptions} height={400} width={400} />
        <span className="text-2xl text-gray-600 mx-auto"> {text} </span>
      </div>
    </div>
  );
};

export default NoDataFound;
