import React from "react";
import SettingsListOne from "./SettingsListOne";
import SettingsListTwo from "./SettingsListTwo";
import SettingsListThree from "./SettingsListThree";
import SocialsTooltip from "./SocialsTooltip";

const SettingsMain = () => {
  return (
    <div className="scrollbar-hide w-auto flex flex-col">
      <h1 className="text-lg font-bold mb-2 px-8">Settings</h1>
      <hr />
      <SettingsListOne />
      <div className="w-full h-2 bg-gray-100"></div>
      <SettingsListTwo />
      <div className="w-full h-2 bg-gray-100"></div>
      <SettingsListThree />
      <div className="w-full h-2 bg-gray-100"></div>
      {/* <SocialsTooltip /> */}
      <p className=" flex text-center items-center justify-center mt-4 text-md text-gray-600">
        @BOCA{" "}
        <span className="text-xs justify-end items-end ml-1 mr-1 mt-1">EN</span>{" "}
        BOCA
      </p>
    </div>
  );
};

export default SettingsMain;
