import React, { useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import LeftAlignedAuthFormHeading from "../components/mini-components/LeftAlignedAuthFormHeading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HampsterLoader from "../components/loading/HampsterLoader";
import { FaRegEye } from "react-icons/fa";
import { PiEyeClosedDuotone } from "react-icons/pi";
import { Toaster, toast } from "sonner";

const NewPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error message when user starts typing
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async () => {
    const { newPassword, confirmPassword } = formData;

    // Validate form fields
    if (!newPassword) {
      setErrors({ ...errors, newPassword: "Please enter a new password" });
      return;
    }

    if (!confirmPassword) {
      setErrors({ ...errors, confirmPassword: "Please confirm your password" });
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrors({ ...errors, confirmPassword: "Passwords do not match" });
      return;
    }

    setLoading(true);
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const { identity, identity_type } = JSON.parse(
        localStorage.getItem("identity")
      );
      const otp = localStorage.getItem("otp");
      const response = await axios.put(
        `${baseUrl}/api/v1/user/forget-password/reset`,
        {
          identity,
          identity_type,
          otp,
          password: newPassword,
          confirm_password: confirmPassword,
        }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success("Password reset success");
        navigate("/login");
      } else {
        toast.success("Failed to update password");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
      // Handle error
    }
  };
  const togglePasswordVisibility = (type) => {
    if (type === "newPassword") {
      setShowPassword(!showPassword);
    } else if (type === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-y-auto bg-[#f9f6f6]">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/30 bg-opacity-50 z-50">
          <HampsterLoader />
        </div>
      )}
      <div className="bg-white lg:w-4/12 md:w-8/12 w-11/12 rounded-[2rem] p-10 shadow-xl flex text-center flex-col mt-10 overflow-y-auto scrollbar-hide max-h-[90%]">
        <div className="flex w-full">
          <IoArrowBackCircleOutline
            className="text-3xl text-black  justify-start items-start text-left cursor-pointer hover:scale-110"
            onClick={() => navigate("/login")}
          />
        </div>
        <div className="">
          <LeftAlignedAuthFormHeading text={"Forget Password"} />
        </div>
        <div className="bg-[#F2F2F2]  rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
          <input
            type={showPassword ? "text" : "password"}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            className="w-10/12 h-full p-3 bg-transparent outline-none"
            placeholder="Enter new password"
          />
          {showPassword ? (
            <PiEyeClosedDuotone
              className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("newPassword")}
            />
          ) : (
            <FaRegEye
              className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("newPassword")}
            />
          )}
        </div>
        {errors.newPassword && (
          <p className="text-red-500 text-xs mt-1">{errors.newPassword}</p>
        )}
        <div className="bg-[#F2F2F2]  rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            className="w-10/12 h-full p-3 bg-transparent outline-none"
            placeholder="Confirm new password"
          />
          {showConfirmPassword ? (
            <PiEyeClosedDuotone
              className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            />
          ) : (
            <FaRegEye
              className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            />
          )}
        </div>
        {errors.confirmPassword && (
          <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>
        )}

        <button
          className="bg-[#404041] p-3 rounded-full mb-2 mt-3 text-white"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default NewPassword;
