import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import moment from "moment";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const NotificationCards = ({
  notifications,
  list,
  showAll,
  setShowAll,
  toggleShowAll,
}) => {
  const [type, setType] = useState();
  const navigate = useNavigate();
  const limitedNotifications = showAll
    ? notifications
    : notifications.slice(0, list);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  const handleNotificationClick = (channelId) => {
    localStorage.setItem("notichannelId", channelId);
    navigate("/messages");
  };
  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    if (now.isSame(createdAt, "day")) {
      const hoursDiff = now.diff(createdAt, "hours");
      const minutesDiff = now.diff(createdAt, "minutes");
      const secondsDiff = now.diff(createdAt, "seconds");

      if (hoursDiff > 0) {
        return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
      } else if (minutesDiff > 0) {
        return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
      } else {
        return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
      }
    } else {
      return createdAt.fromNow();
    }
  };

  return (
    <div>
      {limitedNotifications.map((notification, index) => (
        <div
          key={index}
          className="p-4 bg-[#F5F5F5] rounded-2xl mb-4 w-full relative hover:scale-95 cursor-pointer hover:bg-gray-200 transition-transform ease-in-out duration-300 overflow-y-auto"
          onClick={() => handleNotificationClick(notification.channel_id)}
        >
          <div className="flex">
            <img
              className={`w-10 h-10 rounded-full p-0 object-cover text-orange-500  ${
                notification.sender_user.type === "employer"
                  ? "ring-2 ring-orange-500"
                  : "ring-2 ring-gray-700"
              }`}
              src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${notification.sender_user.profile_image}`}
              alt="Profile"
            />
            <div className="flex flex-col ml-2">
              <span className="text-md font-bold text-gray-600">
                {notification.sender_user.first_name}{" "}
                {notification.sender_user.last_name}
              </span>
              <span
                className={`text-xs flex items-center ${
                  notification.sender_user.type === "job-seeker"
                    ? "text-black"
                    : "text-orange-500"
                }`}
              >
                {capitalizeFirstLetter(notification.sender_user.type)}
                <IoIosCheckmarkCircle className="ml-1" />
              </span>
            </div>
            <div className="flex flex-grow justify-end text-xs">
              <p>{getTimeAgo(notification.created_at)}</p>
            </div>
          </div>
          <div className="mt-2 flex ml-12 text-xs">
            {notification.description}
          </div>
        </div>
      ))}

      {notifications.length > 3 && !showAll && (
        <div className="relative inset-0 flex justify-center items-center">
          {/* Blurred background */}
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="absolute bg-white blur-3xl h-64 w-full"></div>
          </div>

          {/* Button */}
          <button
            onClick={toggleShowAll}
            className="absolute z-50 text-lg cursor-pointer text-white rounded-full px-10 bg-[#404041] p-2 bottom-5"
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationCards;
