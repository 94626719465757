import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthFormHeading from "../components/mini-components/AuthFormHeading";
import RememberMeCheckbox from "../components/mini-components/RememberMeCheckbox";
import WelcomeBack from "../components/mini-components/WelcomeBack";
import SocialLogin from "../components/mini-components/SocialLogin";
import { loginUser } from "../apis";
import { Toaster, toast } from "sonner";
import { FaRegEye } from "react-icons/fa";
import { PiEyeClosedDuotone } from "react-icons/pi";
import { PiEyeClosed } from "react-icons/pi";
import { MdRemoveRedEye } from "react-icons/md";
import axios from "axios";
import HampsterLoader from "../components/loading/HampsterLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email_or_phone: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email_or_phone: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const newErrors = {
      email_or_phone: !formData.email_or_phone
        ? "Email or Phone is required"
        : "",
      password: !formData.password ? "Password is required" : "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleLogin = async () => {
    setLoadingLogin(true);
    if (!validateForm()) {
      toast.error("Please correct the errors in the form");
      return;
    }

    try {
      const response = await loginUser(formData);

      const token = response.content.token;

      localStorage.setItem("token", token);

      navigate("/9974590");

      setTimeout(() => {
        navigate("/");
      }, 2000);
      setLoadingLogin(false);
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      setLoadingLogin(false);
    }
  };
  const handleForgetPassword = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const identityInfo = JSON.parse(localStorage.getItem("identity"));
    if (!baseUrl || !identityInfo) {
      toast.error("Base URL or identity information not found");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/user/forget-password/send-otp`,
        {
          identity: identityInfo.identity,
          identity_type: identityInfo.identity_type,
        }
      );

      if (response.status === 200) {
        setLoading(false); // Set loading to false after successful request
        navigate("/verify-otp");
      } else {
        setLoading(false); // Set loading to false if request fails
        throw new Error("Failed to send OTP");
      }
    } catch (error) {
      setLoading(false); // Set loading to false if there's an error
      toast.error("Error: " + error.message);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-y-auto bg-white pt-5 pb-5">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/30 bg-opacity-50 z-50">
          <HampsterLoader />
        </div>
      )}
      <div className="bg-gray-50 lg:h-screen md:h-screen h-screen lg:w-4/12 md:w-full w-full rounded-[2rem] p-10 lg:p-10 md:p-20 shadow-xl flex text-center flex-col">
        <AuthFormHeading />

        <input
          type="text"
          name="email_or_phone"
          value={formData.email_or_phone}
          onChange={handleInputChange}
          className="bg-[#F2F2F2] p-3 rounded-full mb-2 border-2 border-[#818181] outline-none mt-3"
          placeholder="Email, Phone Number, Or User Name"
        />
        {errors.email_or_phone && (
          <p className="text-red-500 text-xs  text-left ml-5">
            {errors.email_or_phone}
          </p>
        )}
        <div className="bg-[#F2F2F2] rounded-full mb-2 border-2 border-[#818181] outline-none mt-3 w-full flex justify-between items-center px-2">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Enter Password"
            className="w-10/12 h-full p-3 bg-transparent outline-none"
          />
          {showPassword ? (
            <MdRemoveRedEye
              className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            />
          ) : (
            <PiEyeClosed
              className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            />
          )}
        </div>
        {errors.password && (
          <p className="text-red-500 text-xs  text-left ml-5">
            {errors.password}
          </p>
        )}
        <div className="flex w-full">
          <RememberMeCheckbox />
          <div
            className="text-sm text-orange-500 ml-auto items-center text-center p-2 mt-1 cursor-pointer"
            onClick={handleForgetPassword}
          >
            Forget Password
          </div>
        </div>

        <button
          className="bg-[#404041] p-3 rounded-full mb-2 mt-3 text-white hover:bg-gray-500 hover:scale-95 transition-transform ease-in-out duration-300 "
          onClick={handleLogin}
        >
          {loadingLogin && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          Login
        </button>
        <WelcomeBack />
        <SocialLogin />
        <span className="flex mx-auto text-[#818181] text-sm mt-5">
          Don’t Have An Account?
        </span>

        <Link
          className="bg-[#404041] p-3 rounded-full mb-2 mt-3 text-white hover:bg-gray-500 hover:scale-95 transition-transform ease-in-out duration-300"
          to={"/signup"}
        >
          Sign Up for Free
        </Link>
      </div>
    </div>
  );
};

export default Login;
