import React from "react";
import logo from "../../assets/logoBig.png";
import "./loader.css";
const MainLogoLoader = () => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center gap-5">
      <img src={logo} alt="main-logo" className="w-52" />
      <div class="logoloader"></div>
    </div>
  );
};

export default MainLogoLoader;
