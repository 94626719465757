import { FaChevronRight } from "react-icons/fa6";
import logo from "../assets/logoBig.png";
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { Link, useParams } from "react-router-dom";
const TermAndCondition = () => {
  const { name } = useParams();
  const [termsContent, setTermsContent] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://bocaenboca.goodcoderz.com/api/v1/admin/business-settings/get-pages-setup?page_name=${name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setTermsContent(response.data.content.value);
      } catch (error) {
        console.error("Error fetching terms:", error);
        setLoading(false);
      }
    };

    fetchTermsContent();
  }, []);
  const formatPolicyName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <div className="bg-white">
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <img
          className="mx-auto h-12  md:h-14 lg:h-16w-auto"
          src={logo}
          alt="Your Company"
        />
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-orange-500 sm:text-5xl">
            {formatPolicyName(name)}
          </h1>

          {loading ? (
            <div className="flex flex-col w-full gap-5 mt-3 text-center justify-center items-center">
              <p className="w-full h-3 rounded-full bg-gray-300 animate-pulse "></p>
              <p className="w-10/12 h-3 rounded-full bg-gray-300 animate-pulse "></p>
              <p className="w-7/12 h-3 rounded-full bg-gray-300 animate-pulse "></p>
              <p className="w-10/12 h-3 rounded-full bg-gray-300 animate-pulse "></p>
              <p className="w-1/2 h-3 rounded-full bg-gray-300 animate-pulse "></p>
            </div>
          ) : (
            <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
              {" "}
              <div dangerouslySetInnerHTML={{ __html: termsContent }} />
            </p>
          )}
        </div>
        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <ul
            role="list"
            className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5"
          ></ul>
          <div className="mt-10 flex justify-center">
            <Link
              to={"/settings"}
              className="text-sm font-semibold leading-6 text-indigo-600"
            >
              <span aria-hidden="true">&larr;</span>
              Back to settings
            </Link>
          </div>
        </div>
      </main>
      <footer className="border-t border-gray-100 py-6 sm:py-10">
        <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-8 px-6 sm:flex-row lg:px-8">
          <p className="text-sm leading-7 text-gray-400">
            &copy; BOCA en BOCA, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default TermAndCondition;
