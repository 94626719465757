import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import { FaGoogle } from "react-icons/fa";
const SocialLogin = () => {
  return (
    <div className="w-full h-auto flex justify-between items-center px-10 mt-5">
      <FaLinkedin className="text-5xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
      <FaFacebookF className="text-5xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
      <BsApple className="text-5xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
      <FaGoogle className="text-5xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
    </div>
  );
};

export default SocialLogin;
