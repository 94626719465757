import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoShareOutline } from "react-icons/io5";
import { MdOutlineModeComment } from "react-icons/md";
import { CiBookmark } from "react-icons/ci";
import { IoIosBookmark } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import axios from "axios";
import { Modal } from "antd";
import { MdCloseFullscreen } from "react-icons/md";
import { Spin } from "antd";
import Loading from "../loading/Loading";
import { Link } from "react-router-dom";
import PostShimmer from "../loading/PostShimmer";
import avatar from "../../assets/lady.jpg";

const PostBookmarks = () => {
  const [postsAll, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const BASE_URL = "https://bocaenboca.goodcoderz.com/";
  const [userExists, setUserExists] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/list?offset=1&bookmark=yes&limit=100`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPosts(response.data.content.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div className="mt-10">
      {" "}
      {postsAll.map((post) => (
        <div
          key={post.id}
          className="w-full scrollbar-hide"
          style={{
            backgroundColor:
              post.user.username === "@premium"
                ? "#8b551e"
                : post.user.username === "@Test"
                ? "#7e7d7e"
                : "white",
            border:
              post.user.username === "@premium"
                ? "7px solid #E7831D"
                : post.user.username === "@Test"
                ? "7px solid #404041"
                : "none",
            color:
              post.user.username === "@premium" ||
              post.user.username === "@Test"
                ? "white"
                : "black",
            padding:
              post.user.username === "@premium"
                ? "0"
                : post.user.username === "@Test"
                ? "0"
                : "0 1rem",
            borderRadius:
              post.user.username === "@premium" ||
              post.user.username === "@Test"
                ? "0"
                : "0.5rem",
            paddingTop: post.user.username === "@premium" ? "2px" : "0",
            marginBottom: post.user.username === "@Test" ? "10px" : "0",
          }}
        >
          <div
            className={`flex w-full ${
              post.user.username === "@premium" ? " px-8 mt-6" : "px-8 mt-10"
            }`}
          >
            {/* Render user avatar */}
            <img
              className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500 ${
                post.user.type === "employer"
                  ? "ring-2 ring-orange-500"
                  : "ring-2 ring-gray-700"
              }`}
              src={
                post.user.profile_image !== "default.png" &&
                post.user.profile_image
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user.profile_image}`
                  : avatar
              }
              alt="User Avatar"
            />

            <div className="flex flex-col ml-2">
              <Link
                to={`/user/${post.user.id}`}
                className={`text-lg font-bold text-[#5B5B5B] ${
                  post.user.username === "@premium" ||
                  post.user.username === "@Test"
                    ? "text-white"
                    : ""
                }`}
              >
                {post.user.first_name} {post.user.last_name}
              </Link>
              <span
                className={`text-sm flex items-center ${
                  post.user.type === "job-seeker"
                    ? "text-black"
                    : "text-orange-400"
                }`}
              >
                <span
                  className={`text-sm  text-[#5B5B5B] ${
                    post.user.username === "@premium" ||
                    post.user.username === "@Test"
                      ? "text-white"
                      : ""
                  }`}
                >
                  {" "}
                  {post.user.username}{" "}
                </span>
                <GoDotFill className="ml-1 text-xs mr-1" />
                {capitalizeFirstLetter(post.user.type)}

                <IoIosCheckmarkCircle className="ml-1 text-lg" />
              </span>
            </div>
            <span className="text-gray-600 ml-2 block md:hidden lg:hidden text-xs  mt-2">
              {post.user.username}
            </span>

            <div className="flex justify-end text-xs flex-col ml-auto relative top-0">
              <Menu>
                <MenuButton>
                  <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
                </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <MenuItems
                    anchor="bottom end"
                    className="w-52 origin-top-right rounded-xl border border-black/30 bg-white p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md"
                  >
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Report User
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={() => handleBookmarkClick(post.user.id)}
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Bookmark User
                        </button>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>

              <p>{moment(post.created_at).format("ddd h:mm A")}</p>
            </div>
          </div>

          <div
            className={`mx-14 mt-5 $ ${
              post.user.username === "@premium" ? "px-8" : ""
            }`}
          >
            <div>
              {post.description.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line.startsWith("#") ? (
                    <div className="text-orange-500 font-bold">
                      {line} <br />
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: line }} />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          {post.images && post.images.length > 0 && (
            <div className="flex mt-3 ml-14 items-center flex-wrap ">
              {post.images.map((image, index) => (
                <img
                  key={index}
                  className={`mr-2 object-cover cursor-pointer shadow-md rounded-xl ${
                    post.images.length === 1
                      ? "lg:h-64 lg:w-64 md:h-56 md:w-56 w-full gap-3 mt-2"
                      : "lg:h-64 lg:w-64 md:h-56 md:w-56 w-full gap-3 mt-2 "
                  }`}
                  style={{ objectFit: "cover" }}
                  src={`${BASE_URL}${image.file}`}
                  alt={`Post Image ${index + 1}`}
                  onClick={() => openModal(`${BASE_URL}${image.file}`)}
                />
              ))}
            </div>
          )}

          {post.videos && post.videos.length > 0 && (
            <div className="flex mt-3 ml-14  items-center flex-wrap gap-3  ">
              {post.videos.map((video, index) => (
                <video
                  key={index}
                  className={`mr-2 object-cover cursor-pointer shadow-md rounded-xl ${
                    post.videos.length === 1
                      ? "lg:h-64 lg:w-64 md:h-56 md:w-56 w-full gap-3 mt-2"
                      : "lg:h-64 lg:w-64 md:h-56 md:w-56 w-full gap-3 mt-2 "
                  }`}
                  controls
                >
                  <source src={`${BASE_URL}${video.file}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ))}
            </div>
          )}

          {/* Render bookmark button */}
          <div className="flex px-0 w-full mt-14 mb-5">
            {/* <MdOutlineModeComment className="w-1/3 text-2xl" /> */}

            {post.bookMarked && (
              <IoIosBookmark className="ml-auto w-2/4 text-2xl cursor-pointer hover:scale-105" />
            )}
          </div>

          {post.user.username !== "@premium" &&
            post.user.username !== "@Test" && <hr />}
        </div>
      ))}
      {loading && (
        <div className="flex flex-col ">
          <PostShimmer />
          <PostShimmer />
          <PostShimmer />
        </div>
      )}
    </div>
  );
};

export default PostBookmarks;
