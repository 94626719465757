import React, { useState, useEffect } from "react";

import RadioRole from "../components/mini-components/RadioRole";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import LeftAlignedAuthFormHeading from "../components/mini-components/LeftAlignedAuthFormHeading";
import { useNavigate } from "react-router-dom";
import TermAndConditionCheckbox from "../components/mini-components/TermAndConditionCheckbox";

import { Toaster, toast } from "sonner";

import { plans } from "../data/plans";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import axios from "axios";
import { PiEyeClosed } from "react-icons/pi";
import { MdRemoveRedEye } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import HampsterLoader from "../components/loading/HampsterLoader";
import StateInput from "./StateInput";
import CityInput from "./CityInput";
import MainInputComponent from "./MainInputComponent";

const SignUp = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [cities, setCities] = useState([]);

  const [enabledTandC, setEnabledTandC] = useState(true);
  const [selected, setSelected] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailPhone: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    username: "",
    state_id: "",
    city_id: "",
    country_id: "233",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailPhone: "",
    password: "",
    confirmPassword: "",
    username: "",
    state_id: "",
    city_id: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/states/233`
      );
      if (response.data.response_code === "default_200") {
        setStates(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
    setLoading(false);
  };

  const fetchCities = async (stateId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/cities/${stateId}`
      );
      if (response.data.response_code === "default_200") {
        setCities(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
    setLoading(false);
  };

  const handleStateChange = (stateId) => {
    setSelectedState(stateId);
    setFormData({ ...formData, state_id: stateId });
    fetchCities(stateId);
  };

  const handleCityChange = (cityId) => {
    setFormData({ ...formData, city_id: cityId });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else if (type === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validateForm = () => {
    const newErrors = {
      firstName: !formData.firstName ? "First Name is required" : "",
      lastName: !formData.lastName ? "Last Name is required" : "",
      emailPhone: !formData.emailPhone ? "Email is required" : "",
      username: !formData.username ? "Username is required" : "",
      password: !formData.password ? "Password is required" : "",
      state_id: !formData.state_id ? "State is required" : "",
      city_id: !formData.city_id ? "City is required" : "",
      type: !selected ? "Type is required" : "",
      confirmPassword:
        formData.confirmPassword !== formData.password
          ? "Passwords do not match"
          : "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSignUp = async () => {
    if (!validateForm()) {
      toast.error("Please correct the errors in the form");
      return;
    }

    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.emailPhone,
      password: formData.password,
      phone: formData.phoneNumber,
      username: formData.username,
      type: selected,
      country_id: formData.country_id,
      state_id: formData.state_id,
      city_id: formData.city_id,
    };

    try {
      setLoadingLogin(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/auth/registration`,
        payload
      );

      if (response.status === 200) {
        localStorage.setItem(
          "identity",
          JSON.stringify({
            identity: formData.emailPhone,
            identity_type: "email",
          })
        );
        navigate("/otp");
        setLoadingLogin(false);
        toast.success("Registration Success");
      } else {
        setLoadingLogin(false);
        toast.error("Registration failed");
      }
    } catch (error) {
      setLoadingLogin(false);
      toast.error("Registration failed:", error);
    }
  };
  return (
    <div className="flex justify-center items-center w-full h-screen overflow-hidden bg-white overflow-y-auto">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/30 bg-opacity-50 z-50">
          <HampsterLoader />
        </div>
      )}
      <div className="bg-gray-50 lg:w-4/12 md:w-8/12 w-11/12 rounded-[2rem] px-10 py-2 shadow-xl flex text-center flex-col mt-3  max-h-[120%]">
        <div className="flex w-full">
          <IoArrowBackCircleOutline
            className="text-3xl text-black  justify-start items-start text-left cursor-pointer hover:scale-110  relative top-6"
            onClick={() => navigate("/login")}
          />
        </div>
        <LeftAlignedAuthFormHeading text={"Sign Up"} />

        <div className="flex lg:flex-row md:flex-col space-x-3 w-full">
          <div className="flex gap-3 w-full">
            <MainInputComponent
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              error={errors.firstName}
              placeholder="First Name"
            />

            <MainInputComponent
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              error={errors.lastName}
              placeholder="Last Name"
              className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
            />
          </div>
        </div>

        <MainInputComponent
          type="text"
          name="emailPhone"
          value={formData.emailPhone}
          onChange={handleInputChange}
          placeholder="Email"
          error={errors.emailPhone}
          className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
        />

        <div className="flex gap-3 w-full">
          <MainInputComponent
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            error={errors.username}
            placeholder="Username"
            className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
          />

          <MainInputComponent
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder="Phone no."
            className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
          />
        </div>

        <div className="flex gap-3">
          <div className="bg-[#F2F2F2]  rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              className="w-10/12 h-full p-3 bg-transparent outline-none text-sm"
            />
            {showPassword ? (
              <MdRemoveRedEye
                className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("password")}
              />
            ) : (
              <PiEyeClosed
                className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("password")}
              />
            )}
          </div>

          <div className="bg-[#F2F2F2]  rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
              className="w-10/12 h-full p-3 bg-transparent outline-none text-sm"
            />
            {showConfirmPassword ? (
              <MdRemoveRedEye
                className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              />
            ) : (
              <PiEyeClosed
                className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              />
            )}
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-col space-x-20 w-full">
          {errors.password && (
            <p className="text-red-500 text-xs mt-1 text-left ml-5">
              {errors.password}
            </p>
          )}
          {errors.confirmPassword && (
            <p className="text-red-500 text-xs mt-1 text-left ml-5">
              {errors.confirmPassword}
            </p>
          )}
        </div>

        <div className="flex lg:flex-row md:flex-col space-x-2 w-full">
          <StateInput
            value={formData.state_id}
            onChangestate={handleStateChange}
            option={states}
            error={errors.state_id}
          />

          <CityInput
            value={formData.city_id}
            onChangecity={handleCityChange}
            option={cities}
            error={errors.city_id}
          />
        </div>

        <div className="flex lg:flex-row md:flex-col space-x-2 w-full"></div>
        <RadioRole
          selected={selected}
          setSelected={setSelected}
          plans={plans}
        />
        {errors.type && (
          <p className="text-red-500 text-xs mt-1 text-left ml-5">
            {errors.type}
          </p>
        )}
        <TermAndConditionCheckbox
          enabledTandC={enabledTandC}
          setEnabledTandC={setEnabledTandC}
        />
        <button
          className="bg-[#404041] p-3 rounded-full  mt-3 text-white  hover:bg-gray-500 hover:scale-95 transition-transform ease-in-out duration-300 mb-3"
          onClick={handleSignUp}
        >
          {loadingLogin && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          Signup
        </button>
      </div>
    </div>
  );
};

export default SignUp;
