import React, { useState } from "react";
import { Checkbox } from "@headlessui/react";

import { FaCheck } from "react-icons/fa";
const TermAndConditionCheckbox = ({ enabledTandC, setEnabledTandC }) => {
  return (
    <div className="flex space-x-3 justify-start items-center text-center p-1 w-full ">
      <Checkbox
        checked={enabledTandC}
        onChange={setEnabledTandC}
        className="group size-6 rounded-md bg-black/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-orange-500 "
      >
        <FaCheck className="hidden size-4 fill-white group-data-[checked]:block " />
      </Checkbox>
      <p className="text-xs lg:text-sm">
        I Agree To <span className="text-orange-500">Terms of Use</span> And{" "}
        <span className="text-orange-500">Privacy Policy</span>
      </p>
    </div>
  );
};

export default TermAndConditionCheckbox;
