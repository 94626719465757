import React from "react";
import { IoIosHourglass } from "react-icons/io";
const Loading = () => {
  return (
    <div class="flex items-center justify-center h-20 bg-white">
      <div class="relative">
        <div class="h-20 w-20 rounded-full border-t-8 border-b-8 border-gray-500"></div>
        <div class="absolute top-0 left-0 h-20 w-20 rounded-full border-t-8 border-b-8 border-orange-500 animate-spin"></div>
      </div>
    </div>
  );
};

export default Loading;
