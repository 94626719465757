import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IoCameraOutline } from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import { IoIosSend } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import SingleChat from "./SingleChat";
import ChatPerson from "./ChatPerson";

const ChatComponent = ({ chatData, OpenChat }) => {
  const [conversationData, setConversationData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);
  const photoInputRef = useRef(null);
  const endOfMessagesRef = useRef(null);

  const fetchConversation = async () => {
    try {
      setLoading(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/conversation?limit=100&channel_id=${channelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConversationData(response.data.content.conversation.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const notichannelId = localStorage.getItem("notichannelId");
    if (notichannelId) {
      localStorage.removeItem("notichannelId");
    }

    fetchConversation();
    const intervalId = setInterval(fetchConversation, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationData]);

  const handleSendMessage = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const formData = new FormData();
      formData.append("message", message);
      formData.append("channel_id", channelId);

      selectedFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/send-message`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage("");
      setSelectedFiles([]);
      fetchConversation();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="w-full flex flex-col">
      <div>
        <hr />
        <ChatPerson chatData={chatData} OpenChat={OpenChat} />
      </div>
      <SingleChat conversationData={conversationData} />
      <div ref={endOfMessagesRef} />
      <div className="w-auto flex flex-col mt-auto">
        <div className="w-full flex flex-wrap justify-center bg-black/30">
          {selectedFiles.map((file, index) => (
            <div key={index} className="relative m-2">
              <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                className="object-cover rounded-lg h-full"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
              <AiFillCloseCircle
                className="absolute top-0 right-0 text-xl cursor-pointer text-red-500"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
        <div className="bottom-0 left-0 lg:w-full md:w-full w-full bg-white border-t-2 p-5 justify-center items-center space-x-5 flex lg:mx-0 mx-0 md:mx-0 z-50">
          <input
            type="file"
            ref={photoInputRef}
            accept="image/*"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <IoCameraOutline
            className="text-2xl cursor-pointer"
            onClick={() => photoInputRef.current.click()}
          />
          <input
            type="file"
            ref={fileInputRef}
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <ImAttachment
            className="text-xl cursor-pointer"
            onClick={() => fileInputRef.current.click()}
          />
          <div className="w-10/12 h-10 rounded-full bg-gray-100 ml-auto flex">
            <input
              type="text"
              placeholder="type here."
              className="p-2 w-10/12 bg-transparent outline-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div
              className="bg-orange-500 p-2 rounded-full ml-auto hover:scale-95 hover:bg-orange-400 cursor-pointer"
              onClick={handleSendMessage}
            >
              <IoIosSend className="text-white text-2xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
