import React from "react";

const MainInputComponent = ({
  type,
  name,
  value,
  onChange,
  error,
  placeholder,
}) => {
  return (
    <div className="flex flex-col w-full">
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
        placeholder={placeholder}
      />
      {error && (
        <p className="text-red-500 text-xs mt-1 text-left ml-5">{error}</p>
      )}
    </div>
  );
};

export default MainInputComponent;
