import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProfileBanner from "../../profile/ProfileBanner";
import MainLogoLoader from "../loading/MainLogoLoader";
import Posts from "../homepage/Posts";
import UserProfileBanner from "../../profile/UserProfileBanner";

const UserProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get base URL from environment variable
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        // Get token from local storage
        const token = localStorage.getItem("token");

        // Fetch user details
        const userDetailsResponse = await axios.get(
          `${baseURL}/api/v1/user/details/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUserDetails(userDetailsResponse.data.content);

        // Fetch post list
        const postListResponse = await axios.get(
          `${baseURL}/api/v1/user/post/list?limit=100&offset=1&user_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPostList(postListResponse.data.content.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details and post list:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="scrollbar-hide w-full">
      <h1 className="text-lg font-bold mb-2 px-8">Profile</h1>
      <hr />

      {loading ? (
        <MainLogoLoader />
      ) : (
        userDetails && <UserProfileBanner userDetails={userDetails} />
      )}
      <hr />
      {/* Render post list here */}
      <div className="mt-10">
        <Posts postdatas={postList} />
      </div>
    </div>
  );
};

export default UserProfile;
