import React, { useEffect, useState, useRef } from "react";
import ReceiveingMessage from "./chatbox/ReceiveingMessage";
import SendingMessage from "./chatbox/SendingMessage";

const SingleChat = ({ conversationData }) => {
  const storedUserId = localStorage.getItem("userId");
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom whenever messages are updated
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationData]);

  return (
    <div className="h-[25rem] overflow-y-auto scrollbar-hide">
      {conversationData.map((chat, index) => {
        if (chat.user_id === storedUserId) {
          return <SendingMessage key={index} chat={chat} />;
        } else {
          return <ReceiveingMessage key={index} chat={chat} />;
        }
      })}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default SingleChat;
