// Explore.js
import React from "react";
import { CiSearch } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../redux-store/postSearch/searchSlice";
import { toggleActiveTag } from "../redux-store/tagFilter/tagSlice";
import Tags from "./mini-components/Tags";

const Explore = ({ activeComponent }) => {
  const location = useLocation();
  const isBookmarksRoute = location.pathname.includes("/bookmarks");
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);

  const options = {
    job_seeker_post: "Job Seeker Posts",
    emp_profile: "Employer Profiles",
    job_seeker_profile: "Job Seeker Profiles",
    emp_post: "Job Posts",
  };

  const handleTagClick = (tag) => {
    dispatch(toggleActiveTag(tag));
  };

  const handleInputChange = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  return (
    <div className="p-2 flex flex-col justify-start items-start bg-white mt-10">
      <span className="text-lg font-bold text-black flex-1">
        {activeComponent === "Home" ? "Explore" : activeComponent}
      </span>
      <div className="flex space-x-2 w-full mt-3 h-12 rounded-full border-2 border-gray-400 outline-none px-5 items-center">
        <CiSearch className="text-2xl text-gray-600" />
        <input
          type="text"
          className="outline-none"
          placeholder="search"
          value={searchText}
          onChange={handleInputChange}
        />
      </div>
      {!isBookmarksRoute && (
        <Tags
          options={options}
          activeTags={activeTags}
          onTagClick={handleTagClick}
        />
      )}
    </div>
  );
};

export default Explore;
