import React from "react";
import { Modal, Input } from "antd";

const UserNameModal = ({ closeModal }) => {
  return (
    <Modal visible={true} onCancel={closeModal} footer={null}>
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-gray-700 font-bold ">Change Username</h1>
        <Input
          className="w-full border-2 border-gray-300 rounded-full  py-4 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
          placeholder="Write something about yourself..."
        />

        <button className="w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full">
          Save
        </button>
      </div>
    </Modal>
  );
};

export default UserNameModal;
