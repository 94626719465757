import React from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mainlogonew.png";
const PleaseLoginModal = ({
  visible,

  onCancel,
  title = "Please Log In",
  content = "You need to log in to access this feature.",
}) => {
  const navigate = useNavigate();
  return (
    <Modal visible={visible} onCancel={onCancel} title={null} footer={null}>
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-[27rem]">
        <p class="font-inter font-semibold text-2xl leading-7 text-center px-12 py-5 mt-2">
          Please login or create a free account to proceed.
        </p>

        <img
          src={logo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8  md:py-10 p-10 mb-4 "
        />
        <button
          className="p-3 bg-[#404041] text-white font-bold rounded-full shadow-sm text-xl  hover:scale-95 hover:shadow-lg w-10/12 lg:mt-4 mt-0"
          onClick={() => navigate("/login")}
        >
          Take me to Login/Signup
        </button>
      </div>
    </Modal>
  );
};

export default PleaseLoginModal;
