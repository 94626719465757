import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { FaRegCircleXmark } from "react-icons/fa6";
import { MdNavigateNext } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";

import Explore from "../components/Explore";
import Notification from "../components/notifcation/Notification";
import HomeMain from "../components/homepage/HomeMain";

import MessageMain from "../components/messagePage/MessageMain";
import logo from "../assets/mainlogonew.png";
import BookmarkMain from "../components/bookmark/BookmarkMain";
import SubscriptionMain from "../components/Subscription/SubscriptionMain";
import ProfileMain from "../profile/ProfileMain";
import { MdNotificationsActive } from "react-icons/md";

import SettingsMain from "../components/settings/SettingsMain";
import { Toaster, toast } from "sonner";

import ExploreMain from "../components/ExplorePage/ExploreMain";
import UserProfile from "../components/userProfile/UserProfile";

import HomeIcon from "../assets/House.svg";
import ExploreIcon from "../assets/Explore.svg";
import MessagesIcon from "../assets/Message.svg";
import BookmarksIcon from "../assets/Bookmarks.svg";
import SubscriptionIcon from "../assets/Subscription.svg";
import ProfileIcon from "../assets/Profile.svg";
import SettingsIcon from "../assets/Settings.svg";
import SignOutIcon from "../assets/Signout.svg";
import BellIcon from "../assets/Bell.svg";
import BellIconActive from "../assets/Bell-1.svg";
import "./animations.css";
import HomeActive from "../assets/Houseactive.svg";
import ExploreActive from "../assets/Exploreactive.svg";
import MessagesActive from "../assets/Messageactive.svg";
import BookmarkActive from "../assets/Bookmarkactive.svg";
import SubscriptionActive from "../assets/Subscriptionactive.svg";
import ProfileActive from "../assets/Profileactive.svg";
import SettingsActive from "../assets/Settingsactive.svg";
import SignOutActive from "../assets/Signout.svg";
import PleaseLoginModal from "./PleaseLoginModal";
import ChatComponent from "../components/messagePage/ChatComponent";
import SideBarNotification from "../components/sidebarNotification/SideBarNotification";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationSideBar, setNotificationSideBar] = useState(false);
  const [activeComponent, setActiveComponent] = useState("Home");
  const [userExists, setUserExists] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case "/":
        setActiveComponent("Home");
        break;
      case "/explore":
        setActiveComponent("Explore");
        break;
      case "/messages":
        setActiveComponent("Messages");
        break;
      case "/bookmarks":
        setActiveComponent("Bookmarks");
        break;
      case "/notification":
        setActiveComponent("Notification");
        break;
      case "/subscription":
        setActiveComponent("Subscription");
        break;
      case "/profile":
        setActiveComponent("Profile");
        break;
      case "/settings":
        setActiveComponent("Settings");
        break;
      case "/login":
        setActiveComponent("Login");
        break;
      default:
        setActiveComponent("Home");
        break;
    }
  }, [location.pathname]);

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  const navigation = [
    {
      name: "Home",
      href: "/",
      icon: HomeIcon,
      iconActive: HomeActive,
      component: HomeMain,
      current: activeComponent === "Home",
    },
    {
      name: "Explore",
      href: "/explore",
      icon: ExploreIcon,
      iconActive: ExploreActive,
      component: ExploreMain,
      current: activeComponent === "Explore",
    },
    {
      name: "Messages",
      href: "/messages",
      icon: MessagesIcon,
      iconActive: MessagesActive,
      component: MessageMain,
      current: activeComponent === "Messages",
    },
    {
      name: "Bookmarks",
      href: "/bookmarks",
      icon: BookmarksIcon,
      iconActive: BookmarkActive,
      component: BookmarkMain,
      current: activeComponent === "Bookmarks",
    },
    {
      name: "Notification",
      href: "/notification",
      icon: BellIcon,
      iconActive: BellIconActive,
      component: SideBarNotification,
      current: activeComponent === "Notification",
    },
    {
      name: "Subscription",
      href: "/subscription",
      icon: SubscriptionIcon,
      iconActive: SubscriptionActive,
      component: SubscriptionMain,
      current: activeComponent === "Subscription",
    },
    {
      name: userExists ? "Profile" : "Profile",
      href: userExists ? "/profile" : "/login",
      icon: ProfileIcon,
      iconActive: ProfileActive,
      component: ProfileMain,
      current: activeComponent === "Profile",
    },
    {
      name: "Settings",
      href: "/settings",
      icon: SettingsIcon,
      iconActive: SettingsActive,
      component: SettingsMain,
      current: activeComponent === "Settings",
    },
    {
      name: userExists ? "Sign Out" : "Login",
      href: userExists ? "/login" : "/login",
      icon: userExists ? SignOutIcon : SignOutIcon,
      iconActive: userExists ? SignOutActive : SignOutActive,
      component: HomeMain,
      current: activeComponent === (userExists ? "Sign Out" : "Login"),
    },
  ];

  const explorePages = ["Home", "Explore", "Messages", "Bookmarks"];
  const shouldRenderExplore = explorePages.includes(activeComponent);

  const handleNavigationClick = (name, href) => {
    if (!userExists && name !== "Home" && name !== "Explore") {
      setIsLoginModalVisible(true);
    } else {
      if (name === "Sign Out") {
        localStorage.clear();
        navigate("/login");
      } else {
        setActiveComponent(name);
        setSidebarOpen(false);
      }
    }
  };

  const toggleNotificationSideBar = () => {
    setNotificationSideBar((prevState) => !prevState);
  };

  return (
    <div className="fade-in-slow">
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enter95656From="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <FaRegCircleXmark
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-14">
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-5 mt-10">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  onClick={() =>
                                    handleNavigationClick(item.name)
                                  }
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-[#E7831D]"
                                      : item.name === "Login"
                                      ? "text-[#E7831D] font-bold hover:text-[#E7831D] hover:bg-gray-50"
                                      : "text-[#404041] hover:text-[#E7831D] hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-lg leading-7 text-[15px] font-light"
                                  )}
                                >
                                  <SvgIcon
                                    src={
                                      item.current ? item.iconActive : item.icon
                                    }
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-14">
            <nav className="flex flex-1 flex-col mt-10">
              <ul role="list" className="flex flex-1 flex-col gap-y-7 mt-10">
                <li>
                  <ul role="list" className="-mx-2 space-y-5 font-bold ">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => handleNavigationClick(item.name)}
                          className={classNames(
                            item.current
                              ? "bg-gray-50 text-[#E7831D]"
                              : item.name === "Login"
                              ? "text-[#E7831D] font-bold hover:text-[#E7831D] hover:bg-gray-50"
                              : "text-[#404041] hover:text-[#E7831D] hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-lg leading-7 text-[15px] font-light"
                          )}
                        >
                          <SvgIcon
                            src={item.current ? item.iconActive : item.icon}
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          <span
                            className={
                              item.current ? "font-bold text-lg" : " text-lg"
                            }
                          >
                            {item.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MdNavigateNext className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900"></div>
          <a href="#" className="flex text-center ">
            <span className="sr-only">Your profile</span>

            <RxAvatar
              className="h-8 w-8 rounded-full bg-gray-50"
              onClick={() => setActiveComponent("Profile")}
            />
            <MdNotificationsActive
              className="h-8 w-8 text-orange-500 ml-2 hover:scale-110"
              aria-hidden="true"
              onClick={toggleNotificationSideBar}
            />
          </a>
        </div>

        <main className="lg:pl-72 scrollbar-hide w-auto">
          <div className="xl:pr-96">
            <div className=" py-10   lg:py-6">
              <div className=" flex justify-center items-center text-center mb-3 ">
                <img src={logo} alt="logo" className="w-44" />
              </div>
              <Routes>
                <Route path="/" element={<HomeMain />} />
                <Route path="/explore" element={<ExploreMain />} />
                <Route path="/messages" element={<MessageMain />} />
                <Route path="/bookmarks" element={<BookmarkMain />} />
                <Route path="/subscription" element={<SubscriptionMain />} />
                <Route path="/profile" element={<ProfileMain />} />
                <Route path="/settings" element={<SettingsMain />} />
                <Route path="/signout" element={<HomeMain />} />
                <Route path="/user/:id" element={<UserProfile />} />
                <Route path="/notification" element={<SideBarNotification />} />
              </Routes>
            </div>
          </div>
        </main>
        {notificationSideBar && (
          <aside className="fixed inset-y-0 right-0 lg:w-80 md:w-72  border-l border-gray-200 px-1 py-6 sm:px-6 lg:px-3 xl:block bg-white z-20 ">
            <div className="sticky mb-2 ">
              {shouldRenderExplore && (
                <Explore activeComponent={activeComponent} />
              )}
            </div>
            <hr />
            {!shouldRenderExplore && <div className="mt-14"></div>}
            <div className=" max-h-screen scrollbar-hide mt-2 bg-transparent">
              {shouldRenderExplore && <Notification list={3} />}
              {!shouldRenderExplore && <Notification list={4} />}
            </div>
          </aside>
        )}
        <aside className="fixed inset-y-0 right-0 lg:w-96 md:w-72   border-l z-20 border-gray-200 px-1 py-6 sm:px-6 lg:px-3 bg-white hidden md:hidden lg:block xl:block scrollbar-hide ">
          <div className="sticky mb-2 ">
            {shouldRenderExplore && (
              <Explore activeComponent={activeComponent} />
            )}
          </div>
          {shouldRenderExplore && <hr />}

          {!shouldRenderExplore && <div className="mt-14"></div>}

          <div className=" max-h-screen scrollbar-hide mt-2 bg-transparent">
            {shouldRenderExplore && <Notification list={3} />}
            {!shouldRenderExplore && <Notification list={4} />}
          </div>
        </aside>
      </div>{" "}
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </div>
  );
};

export default Home;
