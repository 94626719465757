import React from "react";

const Tags = ({ options, activeTags, onTagClick }) => {
  return (
    <div className="mt-3 flex gap-3 flex-wrap text-center justify-center">
      {Object.keys(options).map((key) => (
        <div
          key={key}
          className={`flex py-1 rounded-lg border-2 px-2 cursor-pointer hover:scale-105 transition-transform ease-in-out duration-300 text-sm justify-center items-center ${
            activeTags.includes(key)
              ? "bg-gradient-to-r from-orange-400 to-orange-500 border-[#cf7316] border-[3px] text-white shadow-md"
              : "bg-gradient-to-r from-neutral-50 to-neutral-100 text-gray-600 border-neutral-400 shadow text-xs py-2"
          }`}
          onClick={() => onTagClick(key)}
        >
          <span className="mr-2">{options[key]}</span>
        </div>
      ))}
    </div>
  );
};

export default Tags;
