import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoShareOutline } from "react-icons/io5";
import { MdOutlineModeComment } from "react-icons/md";
import { CiBookmark } from "react-icons/ci";
import { IoIosBookmark } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";

import { Modal } from "antd";
import { MdCloseFullscreen } from "react-icons/md";
import { Spin } from "antd";
import Loading from "../loading/Loading";
import { Link } from "react-router-dom";
import avatar from "../../assets/lady.jpg";
import { FaRegBookmark } from "react-icons/fa";
const BASE_URL = "https://bocaenboca.goodcoderz.com/";
import { GoDotFill } from "react-icons/go";
import commentIcon from "../../assets/comment.svg";
import bookmark from "../../assets/Bookmarks.svg";
import share from "../../assets/share.svg";
import { LuShare } from "react-icons/lu";
import commentWhite from "../../assets/commentwhite.svg";
import bookMarkwhite from "../../assets/bookmarkwhite.svg";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import User from "./User";

const Posts = ({
  postdatas,
  handleBookmark,
  handleReportPost,
  createChatChannel,
  channelLoading,
  handleDeletePost,
}) => {
  const [bookMarkFetching, setBookMarkFetching] = useState({});
  const [error, setError] = useState(null);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  useEffect(() => {
    setDisplayedPosts(
      postdatas.map((post) => ({ ...post, bookMarked: false }))
    );
  }, [postdatas]);

  const handleBookmarkClick = (userId) => {
    handleBookmark(userId);
  };

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage("");
  };

  const handleBookMark = async (postId, index) => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }
    setBookMarkFetching((prevState) => ({ ...prevState, [postId]: true }));
    setError(null);
    try {
      let formData = new FormData();
      formData.append("post_id", postId);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/bookmark/store`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setDisplayedPosts((prevPosts) => {
          const updatedPosts = [...prevPosts];
          updatedPosts[index].bookMarked = !updatedPosts[index].bookMarked;
          return updatedPosts;
        });
      } else {
        const res = await response.json();
        setError(res.message);
        return;
      }
    } finally {
      setBookMarkFetching((prevState) => ({ ...prevState, [postId]: false }));
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };
  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    if (now.isSame(createdAt, "day")) {
      const hoursDiff = now.diff(createdAt, "hours");
      const minutesDiff = now.diff(createdAt, "minutes");
      const secondsDiff = now.diff(createdAt, "seconds");

      if (hoursDiff > 0) {
        return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
      } else if (minutesDiff > 0) {
        return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
      } else {
        return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
      }
    } else {
      return createdAt.fromNow();
    }
  };

  return (
    <div className="">
      {displayedPosts.map((post, index) => (
        <div
          key={post.id}
          className="w-full scrollbar-hide mt-2 "
          style={{
            backgroundColor:
              post.user.username === "@premium"
                ? "#8b551e"
                : post.user.username === "@Test"
                ? "#7e7d7e"
                : "white",
            border:
              post.user.username === "@premium"
                ? "7px solid #E7831D"
                : post.user.username === "@Test"
                ? "7px solid #404041"
                : "none",
            color:
              post.user.username === "@premium" ||
              post.user.username === "@Test"
                ? "white"
                : "black",
            padding:
              post.user.username === "@premium"
                ? "0"
                : post.user.username === "@Test"
                ? "0"
                : "0 1rem",
            borderRadius:
              post.user.username === "@premium" ||
              post.user.username === "@Test"
                ? "0"
                : "0.5rem",
            paddingTop: post.user.username === "@premium" ? "2px" : "0",
            marginBottom: post.user.username === "@Test" ? "10px" : "0",
          }}
        >
          <div
            className={`flex w-full ${
              post.user.username === "@premium" ? " px-8 mt-6" : "px-8 mt-10"
            }`}
          >
            <img
              className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500 ${
                post.user.type === "employer"
                  ? "ring-2 ring-orange-500"
                  : "ring-2 ring-gray-700"
              }`}
              src={
                post.user.profile_image !== "default.png" &&
                post.user.profile_image
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user.profile_image}`
                  : avatar
              }
              alt="User Avatar"
              style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
            />
            <div className="flex flex-col ml-2">
              <Link
                to={`/user/${post.user.id}`}
                className={`text-lg font-bold text-[#5B5B5B] ${
                  post.user.username === "@premium" ||
                  post.user.username === "@Test"
                    ? "text-white"
                    : ""
                }`}
              >
                {post.user.first_name} {post.user.last_name}
              </Link>
              <span
                className={`text-sm flex items-center ${
                  post.user.type === "job-seeker"
                    ? "text-black"
                    : "text-orange-400"
                }`}
              >
                <span
                  className={`text-sm  text-[#5B5B5B] ${
                    post.user.username === "@premium" ||
                    post.user.username === "@Test"
                      ? "text-white"
                      : ""
                  }`}
                >
                  {" "}
                  {post.user.username}{" "}
                </span>
                <GoDotFill className="ml-1 text-xs mr-1" />
                {capitalizeFirstLetter(post.user.type)}

                <IoIosCheckmarkCircle className="ml-1 text-lg" />
              </span>
            </div>
            {/* <span className="text-gray-600 ml-2 block md:hidden lg:hidden text-xs  mt-2">
              {post.user.username}
            </span> */}
            <div className="flex justify-end text-xs flex-col ml-auto relative top-0">
              <Menu>
                <MenuButton>
                  {!userExists ? (
                    <BsThreeDots
                      className="text-3xl ml-auto cursor-pointer"
                      onClick={() => setIsLoginModalVisible(true)}
                    />
                  ) : (
                    <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
                  )}
                </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <MenuItems
                    anchor="bottom end"
                    className="w-52 origin-top-right rounded-xl border border-black/30 bg-white p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md"
                  >
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Report User
                        </button>
                      )}
                    </MenuItem>
                    {localStorage.getItem("userId") == post.user.id && (
                      <MenuItem>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-black/30 text-white"
                                : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => handleDeletePost(post.id)}
                          >
                            Delete Post
                          </button>
                        )}
                      </MenuItem>
                    )}
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={() => handleReportPost(post.id)}
                        >
                          Report Post
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={() => handleBookmarkClick(post.user.id)}
                          className={`${
                            active ? "bg-black/30 text-white" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          Bookmark User
                        </button>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>

              <p>{getTimeAgo(post.created_at)}</p>
            </div>{" "}
          </div>

          <div
            className={`mx-14 mt-5 $ ${
              post.user.username === "@premium" ? "px-8" : ""
            }`}
          >
            <div>
              {post.description.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line.startsWith("#") ? (
                    <div className="text-orange-500 font-bold">
                      {line} <br />
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: line }} />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          {post.images && post.images.length > 0 && (
            <div className="flex mt-3 ml-14 items-center flex-wrap ">
              {post.images.map((image, index) => (
                <img
                  key={index}
                  className={`mr-2 object-cover cursor-pointer shadow-md rounded-lg ${
                    post.images.length === 1
                      ? "lg:h-64 lg:w-64 md:h-56 md:w-56 w-56 h-56 gap-3 mt-2"
                      : "lg:h-64 lg:w-64 md:h-56 md:w-56 w-56 h-56 gap-3 mt-2 "
                  }`}
                  style={{ objectFit: "cover" }}
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${image.file}`}
                  alt={`Post Image ${index + 1}`}
                  onClick={() => openModal(`${BASE_URL}${image.file}`)}
                />
              ))}
            </div>
          )}

          {post.videos && post.videos.length > 0 && (
            <div className="flex mt-3 ml-14  items-center flex-wrap gap-3  ">
              {post.videos.map((video, index) => (
                <video
                  key={index}
                  className={`mr-2 object-cover cursor-pointer shadow-md rounded-xl ${
                    post.videos.length === 1
                      ? "lg:h-64 lg:w-64 md:h-56 md:w-56 w-full gap-3 mt-2"
                      : "lg:h-64 lg:w-64 md:h-56 md:w-56 w-full gap-3 mt-2 "
                  }`}
                  controls
                >
                  <source
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${video.file}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ))}
            </div>
          )}

          <div className="flex px-0 w-full mt-14 mb-5">
            {localStorage.getItem("userId") !== post.user.id && (
              <>
                {!userExists ? (
                  <SvgIcon
                    src={
                      post.user.username === "@premium" ||
                      post.user.username === "@Test"
                        ? commentWhite
                        : commentIcon
                    }
                    className="h-6 w-1/4 shrink-0 hover:scale-95"
                    aria-hidden="true"
                    onClick={() => setIsLoginModalVisible(true)}
                  />
                ) : (
                  <SvgIcon
                    src={
                      post.user.username === "@premium" ||
                      post.user.username === "@Test"
                        ? commentWhite
                        : commentIcon
                    }
                    className="h-6 w-1/4 shrink-0 hover:scale-105 "
                    aria-hidden="true"
                    onClick={() => createChatChannel(post.id, post.user.id)}
                  />
                )}
              </>
            )}

            {bookMarkFetching[post.id] && <Spin className="ml-auto w-2/4" />}

            {!post.bookMarked && !bookMarkFetching[post.id] && (
              <SvgIcon
                src={
                  post.user.username === "@premium" ||
                  post.user.username === "@Test"
                    ? bookMarkwhite
                    : bookmark
                }
                className="h-6 w-2/4 hover:scale-105 ml-auto"
                aria-hidden="true"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookMark(post.id, index);
                }}
              />
            )}

            {post.bookMarked && (
              <IoIosBookmark className="ml-auto w-2/4 text-2xl cursor-pointer hover:scale-105" />
            )}
            <LuShare
              className={`w-1/4 text-2xl text-gray-700 ${
                post.user.username === "@premium" ||
                post.user.username === "@Test"
                  ? "text-white"
                  : ""
              } `}
            />
          </div>

          {post.user.username !== "@premium" &&
            post.user.username !== "@Test" && <hr />}
        </div>
      ))}

      <Modal
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        width={500}
      >
        <div className="flex flex-col  items-center w-full p-2">
          <img
            src={modalImage}
            alt="Full Screen"
            className="w-full rounded-lg max-h-[37rem] object-cover"
          />

          <button
            onClick={closeModal}
            className="mt-4 ml-auto text-gray-600 p-2 rounded"
          >
            <MdCloseFullscreen className="text-2xl" />
          </button>
        </div>
      </Modal>

      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </div>
  );
};

export default Posts;
