import React, { useState, useEffect } from "react";
import axios from "axios";

import CityModal from "./settingsModals/CityModal";
import StatesModal from "./settingsModals/StatesModal";
import CountryModal from "./settingsModals/CountryModal";
import LanguageModal from "./settingsModals/LanguageModal";
import cityIcon from "../../assets/City.svg";
import stateIcon from "../../assets/state.svg";
import country from "../../assets/globe.svg";
import langIcon from "../../assets/lang.svg";
import editIcon from "../../assets/edit.svg";

const settingsTwo = [
  {
    name: "Country",
    canEdit: true,
    icon: country,
    modal: CountryModal,
    key: "country",
  },
  {
    name: "State",
    canEdit: true,
    icon: stateIcon,
    modal: StatesModal,
    key: "state",
  },
  {
    name: "City",
    canEdit: true,
    icon: cityIcon,
    modal: CityModal,
    key: "city",
  },
  {
    name: "Display Language",
    canEdit: true,
    icon: langIcon,
    modal: LanguageModal,
    key: "current_language_key",
  },
];

const SettingsListTwo = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [userData, setUserData] = useState({});
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data.content);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const openModal = (ModalComponent) => {
    setActiveModal(
      <ModalComponent closeModal={closeModal} fetchUserData={fetchData} />
    );
  };
  const closeModal = () => {
    setActiveModal(null);
  };
  const toggleExpansion = (key) => {
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((k) => k !== key));
    } else {
      setExpandedKeys([...expandedKeys, key]);
    }
  };

  const renderUserData = (itemKey) => {
    if (itemKey === "country" && userData.country) {
      return userData.country.name;
    }
    if (itemKey === "state" && userData.state) {
      return userData.state.name;
    }
    if (itemKey === "city" && userData.city) {
      return userData.city.name;
    }
    if (itemKey === "current_language_key") {
      return userData.current_language_key;
    }
    return "";
  };

  return (
    <>
      {activeModal}
      <ul
        role="list"
        className="divide-y divide-gray-100 overflow-hidden bg-white ring-gray-900/5 sm:rounded-xl"
      >
        {settingsTwo.map((item) => (
          <li
            key={item.name}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
          >
            <div className="flex gap-x-4">
              <img src={item.icon} alt={item.name} className="w-6 h-6" />
              <div className="min-w-0 flex-auto">
                <p className="text-md leading-6 text-gray-700">
                  <span className="absolute inset-x-0 -top-px bottom-0 text-gray-600" />
                  {item.name}
                </p>
                <p className="text-sm max-w-[45rem] z-50">
                  {userData[item.key] !== undefined ? (
                    <>
                      {expandedKeys.includes(item.key) ? (
                        renderUserData(item.key)
                      ) : (
                        <>
                          {renderUserData(item.key).substring(0, 60)}
                          {renderUserData(item.key).length > 60 && (
                            <button
                              onClick={() => toggleExpansion(item.key)}
                              className="text-orange-500 ml-1 focus:outline-none cursor-pointer"
                              style={{ zIndex: 999 }}
                            >
                              ...Read more
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4 z-50">
              {item.canEdit && (
                <img
                  src={editIcon}
                  alt="Edit"
                  className="w-5 h-5 flex-none text-gray-500 cursor-pointer hover:scale-105"
                  aria-hidden="true"
                  onClick={() => openModal(item.modal)}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SettingsListTwo;
