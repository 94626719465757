import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTags: [],
};

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setActiveTags(state, action) {
      state.activeTags = action.payload;
    },
    toggleActiveTag(state, action) {
      const tag = action.payload;
      if (state.activeTags.includes(tag)) {
        state.activeTags = state.activeTags.filter((t) => t !== tag);
      } else {
        state.activeTags.push(tag);
      }
    },
    clearActiveTags(state) {
      state.activeTags = [];
    },
  },
});

export const { setActiveTags, toggleActiveTag, clearActiveTags } =
  tagSlice.actions;
export default tagSlice.reducer;
