import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./postSearch/searchSlice";
import tagReducer from "./tagFilter/tagSlice";

const store = configureStore({
  reducer: {
    search: searchReducer,
    tag: tagReducer,
  },
});

export default store;
